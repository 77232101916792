import { DiscountAggregationType, DiscountType } from '../../../constants/Strings';

export interface ICartItemPermission {
  update: boolean;
}

interface IBUInfo {
  buId: number;
  buName: string;
}

export interface INewCartItem {
  cartItemAliasId: string,
  catalogDiscount: number,
  catalogDiscountPercentage: number,
  deliveryAt: number,
  discountType: DiscountType,
  discountAggregationType: DiscountAggregationType,
  finalSellingPrice: number,
  imageUrl: string,
  lineDiscount: number,
  mrp: number,
  skuId: string,
  productId: number,
  productName: string,
  productCategoryName: string,
  productCategoryAliasId: string,
  buInfo: IBUInfo,
  quantity: number,
  sellingPrice: number,
  taxClass: string,
  taxRate: number,
  totalValue: number,
  productValue: number,
  productGst: number,
  totalSaved: number,
  skuComment: string,
  measurementUnitId: number,
  measurementUnitName: string,
  measurementUnitQty: string,
  uomDecimalPrecision: number,
  permission: ICartItemPermission;
}

export class NewCartItemList {
  cartItemList: Array<INewCartItem> = [];
  constructor(cartItemList: Array<INewCartItem>) {
    this.cartItemList = cartItemList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<INewCartItem>,
  payloadList: Array<INewCartItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
