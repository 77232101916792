import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar,
} from '@mui/material';
import { EuroSymbolOutlined, ListAltOutlined } from '@mui/icons-material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import clsx from 'clsx';

import { useTypedSelector } from '../../config/store';
import { customerOverviewFeatureFlags } from '../../constants/FeatureFlags';
import { ModuleUserPermissions } from '../../constants/Permissions';
import GenerateLabelIcon from '../../assets/images/GenerateLabelIcon';
import { hasRolePermission } from '../../utils/common';
import useStyles from './style';

enum Routes {
  ORDER_MANAGEMENT = 'orderManagement',
  CASH_MANAGEMENT = 'cashmanagement',
  CUSTOMER_TRANSACTIONS = 'customerTransactions',
  LABEL_MANAGEMENT = 'labelManagement',
  DISPLAY_MANAGEMENT = 'displayManagement',
  DEALS = 'deals',
}

enum SubRoutes {
  ORDER_LIST = 'orders',
  SAVED_CARTS = 'savedcarts',
  CASH_CLOSURE = 'cashclosure',
  CASH_TRANSACTIONS = 'cashtransactions',
  LEDGER = 'ledger',
  REFUND = 'refunds',
  RETURN = 'returns',
}

interface IDrawerNavigation {
  isOffline: boolean;
}

const DrawerNavigation: FunctionComponent<IDrawerNavigation> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('router');
  const location = useLocation();

  const { permission } = useTypedSelector((state) => state.userProfile.userProfileData);
  const roles = useTypedSelector((state) => state.userProfile.userProfileData?.userData?.userPermissionAndEntityPayload?.roles) || [];

  const [selectedRoute, setSelectedRoute] = useState({ route: '', subRoute: '' });

  const hasPermission = (reqModulePermission?: string[]) => {
    if (Object.keys(permission).length === 0) return false;
    if (!reqModulePermission) return true;
    return (Object.keys(permission?.workspace ?? {}).some((item) => reqModulePermission?.includes(item)));
  };

  useEffect(() => {
    const route = location.pathname.split('/')?.[1];
    setSelectedRoute({ route, subRoute: location.pathname.split('/')?.[2] || route });
  }, [location.pathname]);

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawer }}
    >
      <Toolbar classes={{ root: clsx(props.isOffline && classes.offlineDrawer) }} />
      <List component="nav">
        { hasPermission([ModuleUserPermissions.STORE_ADMIN]) && (
          <>
            <ListItem className={classes.listItem} id={Routes.ORDER_MANAGEMENT}>
              <ListItemIcon className={classes.listItemIconArea}>
                <ListAltOutlined className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText primary={t(Routes.ORDER_MANAGEMENT)} />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem
                id="navigationMenu-button-orderList"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.ORDER_LIST && classes.activeListItem)}
                component={Link}
                to="/orders"
              >
                <ListItemText primary={t(SubRoutes.ORDER_LIST)} />
              </ListItem>
              <ListItem
                id="navigationMenu-button-savedCarts"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.SAVED_CARTS && classes.activeListItem)}
                component={Link}
                to="/cart/savedcarts"
              >
                <ListItemText primary={t(SubRoutes.SAVED_CARTS)} />
              </ListItem>
            </List>
          </>
        )}
        { hasPermission([ModuleUserPermissions.STORE_ADMIN]) && (
          <>
            <ListItem className={classes.listItem} id={Routes.CASH_MANAGEMENT}>
              <ListItemIcon className={classes.listItemIconArea}>
                <EuroSymbolOutlined className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText primary={t(Routes.CASH_MANAGEMENT)} />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem
                id="navigationMenu-button-cashClosure"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.CASH_CLOSURE && classes.activeListItem)}
                component={Link}
                to="/cash/cashclosure"
              >
                <ListItemText primary={t(SubRoutes.CASH_CLOSURE)} />
              </ListItem>
              <ListItem
                id="navigationMenu-button-allCashTransactions"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.CASH_TRANSACTIONS && classes.activeListItem)}
                component={Link}
                to="/cash/cashtransactions"
              >
                <ListItemText primary={t(SubRoutes.CASH_TRANSACTIONS)} />
              </ListItem>
            </List>
          </>
        )}
        { hasPermission([ModuleUserPermissions.STORE_ADMIN]) && (
          <>
            <ListItem className={classes.listItem} id={Routes.CUSTOMER_TRANSACTIONS}>
              <ListItemIcon className={classes.listItemIconArea}>
                <PermIdentityIcon className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primaryContainer }} primary={t(Routes.CUSTOMER_TRANSACTIONS)} />
            </ListItem>
            <List component="div" disablePadding>
              {customerOverviewFeatureFlags.ledger && (
                <ListItem
                  id="navigationMenu-button-ledger"
                  button
                  className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.LEDGER && classes.activeListItem)}
                  component={Link}
                  to="/ledger"
                >
                  <ListItemText primary={t(SubRoutes.LEDGER)} />
                </ListItem>
              )}
              <ListItem
                id="navigationMenu-button-refunds"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.REFUND && classes.activeListItem)}
                component={Link}
                to="/refunds"
              >
                <ListItemText primary={t(SubRoutes.REFUND)} />
              </ListItem>
              <ListItem
                id="navigationMenu-button-returns"
                button
                className={clsx(classes.nested, selectedRoute.subRoute === SubRoutes.RETURN && classes.activeListItem)}
                component={Link}
                to="/returns"
              >
                <ListItemText primary={t(SubRoutes.RETURN)} />
              </ListItem>
            </List>
          </>
        )}
        <ListItem
          id={Routes.LABEL_MANAGEMENT}
          button
          className={clsx(classes.listItem, selectedRoute.route === Routes.LABEL_MANAGEMENT && classes.activeListItem)}
          component={Link}
          to="/labelManagement"
        >
          <ListItemIcon className={classes.listItemIconArea}>
            <GenerateLabelIcon className={classes.listItemIcon} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primaryContainer }} primary={t(Routes.LABEL_MANAGEMENT)} />
        </ListItem>
        <ListItem
          id={Routes.DISPLAY_MANAGEMENT}
          button
          className={clsx(classes.listItem, selectedRoute.route === Routes.DISPLAY_MANAGEMENT && classes.activeListItem)}
          component={Link}
          to="/displayManagement"
        >
          <ListItemIcon className={classes.listItemIconArea}>
            <ViewComfyOutlinedIcon className={clsx(classes.listItemIcon,
              selectedRoute.route === Routes.DISPLAY_MANAGEMENT && classes.activeListItem)}
            />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primaryContainer }} primary={t(Routes.DISPLAY_MANAGEMENT)} />
        </ListItem>
        { hasRolePermission(roles, [ModuleUserPermissions.MODULAR_KITCHEN_DESIGNER]) && (
          <ListItem
            id={Routes.DEALS}
            button
            className={clsx(classes.listItem, selectedRoute.route === Routes.DEALS && classes.activeListItem)}
            component={Link}
            to="/deals"
          >
            <ListItemIcon className={classes.listItemIconArea}>
              <PeopleAltOutlinedIcon className={clsx(classes.listItemIcon,
                selectedRoute.route === Routes.DEALS && classes.activeListItem)}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.primaryContainer }} primary={t(Routes.DEALS)} />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default DrawerNavigation;
