export enum ModuleUserPermissions {
  STORE_ADMIN = 'STORE_ADMIN',
  STORE_MANAGER = 'STORE_MANAGER',
  STORE_ACCOUNTANT = 'STORE_ACCOUNTANT',
  STORE_LOGISTICS_COORDINATOR = 'STORE_LOGISTICS_COORDINATOR',
  FOFO_ACCOUNTS_HO = 'FOFO_ACCOUNTS_HO',
  FOFO_CUSTOMER_SUPPORT = 'FOFO_CUSTOMER_SUPPORT',
  SALES_REPRESENTATIVE = 'SALES_REPRESENTATIVE',
  STORE_VIEWER = 'FOFO_STORE_VIEWER',
  MODULAR_KITCHEN_DESIGNER = 'MODULAR_KITCHEN_DESIGNER',
}

export enum UserPermissions {
  FOFO_DAILY_CLOSING = 'FOFO_DAILY_CLOSING',
  FOFO_CONFIRM_PAYMENT = 'FOFO_CONFIRM_PAYMENT',
  FOFO_EDIT_ORDER = 'FOFO_EDIT_ORDER',
  FOFO_START_PROCESSING_PARENT = 'FOFO_START_PROCESSING_PARENT',
  FOFO_ADD_PAYMENT = 'FOFO_ADD_PAYMENT',
  FOFO_ACCESS_CART = 'FOFO_ACCESS_CART',
  FOFO_ADD_CMS = 'FOFO_ADD_CMS',
  FOFO_EDIT_CMS = 'FOFO_EDIT_CMS',
  FOFO_EDIT_CLOSURE = 'FOFO_EDIT_CLOSURE',
  FOFO_RECORD_REFUND = 'FOFO_RECORD_REFUND',
  FOFO_CONFIRM_REFUND = 'FOFO_CONFIRM_REFUND',
  FOFO_CANCEL_REFUND = 'FOFO_CANCEL_REFUND',
  FOFO_CONFIRM_EDIT = 'FOFO_CONFIRM_EDIT',
  FOFO_NEW_ORDER = 'FOFO_NEW_ORDER',
  FOFO_EXPORT_CLOSURE = 'FOFO_EXPORT_CLOSURE',
  FOFO_START_PROCESSING_CHILD = 'FOFO_START_PROCESSING_CHILD',
  FOFO_DELETE_SCART = 'FOFO_DELETE_SCART',
  FOFO_DELETE_CART = 'FOFO_DELETE_CART',
  FOFO_CONFIRM_ORDER = 'FOFO_CONFIRM_ORDER',
  FOFO_CANCEL_PARENT_ORDER = 'FOFO_CANCEL_PARENT_ORDER',
  FOFO_DISPATCH_CHILD = 'FOFO_DISPATCH_CHILD',
  FOFO_RETURN_SUPPLY_ORDER = 'FOFO_RETURN_SUPPLY_ORDER',
  FOFO_CANCEL_SUPPLY_ORDER = 'FOFO_CANCEL_SUPPLY_ORDER',
}
