import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import * as _ from 'lodash';

import { useTypedSelector } from '../config/store';
import StoreMainLayout from '../layout/StoreMainLayout';
import { hasRolePermission } from '../utils/common';

interface IPrivateRoute {
  element: React.ReactElement;
  reqModulePermission?: string[],
}
const PrivateRoute: FunctionComponent<IPrivateRoute> = (props) => {
  const userProfileState = useTypedSelector((state) => state.userProfile);
  const { permission } = userProfileState.userProfileData;
  const roles = userProfileState.userProfileData.userData.userPermissionAndEntityPayload?.roles || [];

  const hasPermission = () => {
    if (Object.keys(permission).length === 0) return false;
    if (!props.reqModulePermission) return true;
    return (Object.keys(permission?.workspace ?? {}).some((item) => props.reqModulePermission?.includes(item)));
  };

  return (
    <>
      { userProfileState.isSuccess && (hasPermission() || hasRolePermission(roles, props.reqModulePermission))
      && <StoreMainLayout>{props.element}</StoreMainLayout> }
      { userProfileState.isSuccess && !hasPermission() && !hasRolePermission(roles, props.reqModulePermission)
      && <Navigate to={{ pathname: '/nopermission' }} /> }
    </>
  );
};

export default PrivateRoute;
