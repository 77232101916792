import React, { FunctionComponent, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router, Route, Navigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { autoLogin, autoLoginReset } from '../feature/auth/actions/AutoLogin';
import Login from '../feature/auth/pages/Login';
import PrivateRoute from './PrivateRoute';
import { customerOverviewFeatureFlags } from '../constants/FeatureFlags';
import { ModuleUserPermissions } from '../constants/Permissions';
import { SentryRoutes } from '../config/sentry';
import { useTypedSelector } from '../config/store';
import { analytics, AnalyticsPlatform } from '../config/analytics';
import { fetchRemoteConfig } from '../feature/auth/actions/fetchRemoteConfig';

const CartProfile = React.lazy(() => import('../feature/cart/pages/CartProfile'));
const OrderList = React.lazy(() => import('../feature/order/pages/OrderList'));
const CustomerProfile = React.lazy(() => import('../feature/customer/pages/CustomerProfile'));
const OrderProfile = React.lazy(() => import('../feature/order/pages/OrderProfile'));
const UpdateOrderSKU = React.lazy(() => import('../feature/order/pages/UpdateOrderSku'));
const NoPermission = React.lazy(() => import('../feature/auth/pages/NoPermission'));
const SavedCartList = React.lazy(() => import('../feature/cart/pages/SavedCartList'));
const CashClosure = React.lazy(() => import('../feature/cash/pages/CashClosure'));
const CashTransactions = React.lazy(() => import('../feature/cash/pages/CashTransactions'));
const AddCashClosure = React.lazy(() => import('../feature/cash/pages/AddCashClosure'));
const LedgerStatement = React.lazy(() => import('../feature/ledger/pages/LedgerStatement'));
const RefundListing = React.lazy(() => import('../feature/refund/pages/RefundListing'));
const RecordRefund = React.lazy(() => import('../feature/refund/pages/RecordRefund'));
const ReturnSku = React.lazy(() => import('../feature/return/pages/ReturnSku'));
const ReturnList = React.lazy(() => import('../feature/return/pages/ReturnList'));
const LabelManagement = React.lazy(() => import('../feature/labelManagement/pages/LabelManagement'));
const LabelPreview = React.lazy(() => import('../feature/labelManagement/pages/LabelPreview'));
const DisplayManagement = React.lazy(() => import('../feature/displayManagement/pages/DisplayManagement'));
const OptimisedList = React.lazy(() => import('../feature/displayManagement/pages/OptimisedList'));
const DealList = React.lazy(() => import('../feature/deal/pages/DealList'));
const DealProfile = React.lazy(() => import('../feature/deal/pages/DealProfile'));

const RootNavigation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const userProfileState = useTypedSelector((state) => state.userProfile);
  const userProfileData = userProfileState.userProfileData.userData;

  useEffect(() => {
    dispatch(fetchRemoteConfig());
    dispatch(autoLogin({ info: { device: { deviceName: 'desktop' } } }));
    return () => { dispatch(autoLoginReset()); };
  }, []);

  useEffect(() => {
    if (userProfileState.isSuccess) {
      analytics.setUser({
        type: AnalyticsPlatform.mixpanel,
        userName: `${userProfileData.firstName} ${userProfileData.lastName}`,
        distinctKey: userProfileData.userAliasId,
        userPhone: userProfileData.phoneNumber ?? '',
      });
    }
  }, [userProfileState.isSuccess]);

  return (
    <Router>
      {/* TODO: need to added a suspense page once design available */}
      <Suspense fallback={<div>Loading...</div>}>
        <SentryRoutes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/cart/savedcarts"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<SavedCartList />}
              />
            )}
          />
          <Route
            path="/cart/:cartAliasId"
            element={(
              <PrivateRoute
                reqModulePermission={[
                  ModuleUserPermissions.STORE_ADMIN,
                  ModuleUserPermissions.STORE_ACCOUNTANT,
                  ModuleUserPermissions.SALES_REPRESENTATIVE,
                  ModuleUserPermissions.STORE_MANAGER,
                ]}
                element={<CartProfile />}
              />
            )}
          />
          <Route
            path="/orders"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<OrderList />}
              />
            )}
          />
          <Route
            path="/customer/:customerId"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<CustomerProfile />}
              />
            )}
          />
          <Route
            path="/orders/:orderReferenceId/cancelSku/:supplyOrderAliasId"
            element={(
              <PrivateRoute
                reqModulePermission={[
                  ModuleUserPermissions.STORE_ADMIN,
                  ModuleUserPermissions.STORE_ACCOUNTANT,
                  ModuleUserPermissions.SALES_REPRESENTATIVE,
                  ModuleUserPermissions.STORE_MANAGER,
                ]}
                element={<UpdateOrderSKU />}
              />
            )}
          />
          <Route
            path="/orders/:orderReferenceId"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<OrderProfile />}
              />
            )}
          />
          <Route
            path="/cash/cashtransactions"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<CashTransactions />}
              />
            )}
          />
          <Route
            path="/cash/cashclosure/add"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<AddCashClosure />}
              />
            )}
          />
          <Route
            path="/cash/cashclosure/:cashClosureId"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<AddCashClosure />}
              />
            )}
          />
          <Route
            path="/cash/cashclosure"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<CashClosure />}
              />
            )}
          />
          {customerOverviewFeatureFlags.ledger && (
            <Route
              path="/ledger"
              element={(
                <PrivateRoute
                  reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                  element={<LedgerStatement />}
                />
              )}
            />
          )}
          <Route
            path="/refunds"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<RefundListing />}
              />
            )}
          />
          <Route
            path="/refunds/recordrefund"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<RecordRefund />}
              />
            )}
          />
          <Route
            path="/orders/:orderReferenceId/returnSku/:supplyOrderAliasId"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN, ModuleUserPermissions.FOFO_CUSTOMER_SUPPORT]}
                element={<ReturnSku />}
              />
            )}
          />
          <Route
            path="/returns"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.STORE_ADMIN]}
                element={<ReturnList />}
              />
            )}
          />
          <Route
            path="/labelManagement"
            element={(
              <PrivateRoute
                element={<LabelManagement />}
              />
            )}
          />
          <Route
            path="/labelPreview"
            element={<LabelPreview />}
          />
          <Route
            path="/displayManagement"
            element={(
              <PrivateRoute
                element={<DisplayManagement />}
              />
            )}
          />
          <Route
            path="/displayManagement/optimisedList/:processAliasId"
            element={(
              <PrivateRoute
                element={<OptimisedList />}
              />
            )}
          />
          <Route
            path="/deals"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.MODULAR_KITCHEN_DESIGNER]}
                element={<DealList />}
              />
                )}
          />
          <Route
            path="/deals/:dealAliasId"
            element={(
              <PrivateRoute
                reqModulePermission={[ModuleUserPermissions.MODULAR_KITCHEN_DESIGNER]}
                element={<DealProfile />}
              />
                )}
          />
          <Route
            path="/nopermission"
            element={<NoPermission />}
          />
          <Route path="/" element={<Navigate to="/orders" />} />
        </SentryRoutes>
      </Suspense>
    </Router>
  );
};

export default RootNavigation;
